import {type MetaFunction} from '@remix-run/node';
import {Link} from '@remix-run/react';
import Cookies from 'js-cookie';
import {type ChangeEvent, useState} from 'react';
import {Button} from '#/app/components/Button.jsx';
import {__DEV__} from '#/app/constants.js';
import {createMetaHandler} from '#/app/helpers/createMetaHandler.js';
import {createMetaTags} from '#/app/helpers/createMetaTags.js';
import * as gtag from '#/app/helpers/gtag.client.js';

export default function Login() {
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const [isFormStarted, setIsFormStarted] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isFormStarted) {
      setIsFormStarted(true);
      gtag.event({action: 'form_start', form_id: 'loign'});
    }
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setIsFormStarted(false);

    const req = await fetch('https://app.ufleet.io/auth', {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const res = await req.json();
    const url = res?.settings?.clientUrl;
    if (url) {
      gtag.event({action: 'form_submit', form_id: 'loign'});
      const domain = getCookieDomain();
      Cookies.set('OAUTH_TOKEN', res.accessToken, {domain, expires: 1}); // , secure: !global.__DEV__});
      Cookies.set('USER_ID', res.id, {domain, expires: 1}); // , secure: !global.__DEV__});
      window.location.href = `https://${url}`;
    } else {
      const errors = res?.errors || [];
      setErrors(errors.length ? errors : [{message: 'An error occurred. Please try again.'}]);
      gtag.event({action: 'form_error', errors: JSON.stringify(errors), form_id: 'loign'});
    }
  };

  return (
    <div className="flex size-full items-center justify-center">
      <div className="w-10/12 space-y-8 py-16 md:w-1/2 lg:py-0">
        <h1 className="text-3xl font-medium">Sign in to your account</h1>
        <div className="space-y-4">
          {errors.length > 0 && (
            <div>
              {errors.map((e: {message: string}) => (
                <p className="text-red-500" key={e.message}>
                  {e.message}
                </p>
              ))}
            </div>
          )}
          <div>
            <label className="block text-xl font-medium text-gray-700" htmlFor="email">
              Email
            </label>
            <div className="mt-2 flex rounded-md shadow-sm">
              <input
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 px-3.5 py-2.5 placeholder:text-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-lg"
                id="email"
                name="email"
                onChange={handleChange}
                placeholder=""
                type="text"
                value={data.email}
              />
            </div>
          </div>
          <div>
            <label className="block text-xl font-medium text-gray-700" htmlFor="password">
              Password
            </label>
            <div className="mt-2 flex rounded-md shadow-sm">
              <input
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 px-3.5 py-2.5 placeholder:text-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-lg"
                id="password"
                name="password"
                onChange={handleChange}
                placeholder=""
                type="password"
                value={data.password}
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            appearance="primary"
            className="_cta flex-1"
            color="sky"
            onClick={handleSubmit}
            size="lg"
          >
            Login
          </Button>
        </div>
        <p className="text-gray-500">
          Don't have an account?
          {' '}
          <Link className="text-sky-500 hover:text-sky-600 hover:underline" to="/register">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
}

export const meta: MetaFunction = createMetaHandler(({location}) => {
  return [
    ...createMetaTags({
      description: `Welcome to the client portal of Ufleet's route optimization software.`,
      title: 'Sign in to the Ufleet Dashboard - Ufleet',
      url: `https://ufleet.io${location.pathname}`,
    }),
  ];
});

function getCookieDomain() {
  const domain = window.location.hostname;

  if (__DEV__) {
    return domain;
  }

  const parts = domain.split('.');
  if (parts.length > 2) {
    parts.shift();
  }

  return parts.join('.');
}
